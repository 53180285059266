import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import { Select } from '@amzn/awsui-components-react/polaris';
import {
  useCalibratedPanelReviewerRecords,
  usePanelReviewerActions,
  usePanelReviewSession,
  usePanelReviewSessionActions,
} from '@/api/panel-review';
import { useAppContext } from '@/contexts';
import { CalibratedReviewerItem, PanelReviewSessionResource, Nullable } from '@/models';
import { DocumentReviewSessionState, PanelReviewState } from '@/api/API';
import { PanelReviewSessionsPage } from '@/common/pages';
import useNavigator from '@/common/hooks/use-navigator';
import {
  checkCandidate,
  checkChimeLink,
  checkDateTime,
  checkDocumentReview,
  checkPanelReviewers,
  checkSkipReviewer,
  checkWorkdocsLink,
} from './form-validator';
import { getButtonText } from './common-components';
import PromoSpinner from '../common/PromoSpinner';
import { useDirectReports, useManagementChain } from '@/api/amzn-people';
import { useDocumentReviewSessionsByCandidate } from '@/api/document-review';
import SelectDateTime from '../common/SelectDateTime';
import { ElevateDateTime, getISO8601WithTimezoneOffset } from '@/common/date-time-helpers';

interface FormParams {
  panelReviewSessionId?: string;
}

const PanelReviewSessionCreateEditForm = ({ panelReviewSessionId }: FormParams): JSX.Element => {
  const { currentUser, spoofUser } = useAppContext();
  const user = spoofUser?.alias || currentUser?.alias;
  const [id, setId] = useState(panelReviewSessionId);
  const [candidateAlias, setCandidateAlias] = useState('');
  const { directReports } = useDirectReports(user);
  const { managementChain } = useManagementChain(user);
  const { listDocumentReviewSessionsByCandidateAlias } = useDocumentReviewSessionsByCandidate();
  const [documentReviewOptions, setDocumentReviewOptions] = useState([{}]);
  const [documentReviewOptionsEnabled, setDocumentReviewOptionsEnabled] = useState(false);
  const candidateOptions = directReports.map((candidate) => ({
    label: `${candidate.name} - ${candidate.alias}`,
    value: candidate.alias,
  }));
  const [reviewers, setReviewers] = useState<string[]>([]);
  const [originalReviewers, setOriginalReviewers] = useState<CalibratedReviewerItem[]>([]);
  const [workdocsLink, setWorkdocsLink] = useState('');
  const [reviewDateTimeObject, setReviewDateTimeObject] = useState<Nullable<ElevateDateTime>>();
  const [startDateTime, setStartDateTime] = useState('');
  const [skipReviewer, setSkipReviewer] = useState('');
  const skipReviewerOptions = managementChain
    .filter((manager) => manager.personId !== user)
    .map((manager) => ({
      label: `${manager.firstName} ${manager.lastName} (@${manager.username}) - L${manager.jobLevel} - ${manager.businessTitle}`,
      value: manager.username,
    }));
  const [completedDocumentReview, setCompletedDocumentReview] = useState('');
  const [chimeLink, setChimeLink] = useState('');
  const { panelReviewSession, isPanelReviewSessionLoading } = usePanelReviewSession(id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { goToPage } = useNavigator();
  const { reviewSessionActions: panelReviewActions } = usePanelReviewSessionActions(id);
  const { attachReviewerByAlias, detachReviewer } = usePanelReviewerActions();
  const { calibratedPanelReviewers } = useCalibratedPanelReviewerRecords();
  const reviewerOptions = calibratedPanelReviewers
    .filter((reviewer) => reviewer.alias !== user && reviewer.alias !== candidateAlias)
    .map((reviewer) => ({
      label: reviewer.alias,
      value: reviewer.alias,
    }));
  const [candidateValidationText, setCandidateValidationText] = useState<string>();
  const [skipReviewerValidationText, setSkipReviewerValidationText] = useState<string>();
  const [documentReviewValidationText, setDocumentReviewValidationText] = useState<string>();
  const [reviewerValidationText, setReviewerValidationText] = useState<string>();
  const [wdLinkValidationText, setWDLinkValidationText] = useState<string>();
  const [chimeLinkValidationText, setChimeLinkValidationText] = useState<string>();
  const [dateTimeValidationText, setDateTimeValidationText] = useState<string>();

  const isParamsValid = () => {
    let isValid = true;

    const candidateError = checkCandidate(candidateAlias);
    const skipReviewerError = checkSkipReviewer(skipReviewer);
    const documentReviewError = checkDocumentReview(completedDocumentReview);
    const reviewersError = checkPanelReviewers(reviewers, user, candidateAlias);
    const wdError = checkWorkdocsLink(workdocsLink);
    const chimeLinkError = checkChimeLink(chimeLink);
    const dateError = checkDateTime(startDateTime);
    setCandidateValidationText(candidateError);
    setSkipReviewerValidationText(skipReviewerError);
    setDocumentReviewValidationText(documentReviewError);
    setReviewerValidationText(reviewersError);
    setWDLinkValidationText(wdError);
    setDateTimeValidationText(dateError);
    setChimeLinkValidationText(chimeLinkError);

    if (candidateError || reviewersError || wdError || dateError) {
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    if (panelReviewSession) {
      setCandidateAlias(panelReviewSession.candidateAlias);
      setWorkdocsLink(panelReviewSession.workdocsLink);
      setChimeLink(panelReviewSession.chimeLink ?? '');
      setSkipReviewer(panelReviewSession.areaLeadAlias);
      setCompletedDocumentReview('');
      const completedDocumentReviews = listDocumentReviewSessionsByCandidateAlias({
        candidateAlias: panelReviewSession.candidateAlias,
        state: [DocumentReviewSessionState.COMPLETE],
      });

      void completedDocumentReviews.then((sessions) => {
        if (sessions) {
          setDocumentReviewOptions(
            sessions.map((session) => ({
              label: `${session.id} - ${session.sessionStart}`,
              value: session.id,
            }))
          );

          setDocumentReviewOptionsEnabled(sessions.length > 0);
        }
      });
      setCompletedDocumentReview(panelReviewSession.documentReviewSession);

      if (panelReviewSession?.panelReviewers.length) {
        setReviewers(panelReviewSession.panelReviewers.map((reviewer) => reviewer.alias));
        setOriginalReviewers(panelReviewSession.panelReviewers);
      }
      if (panelReviewSession.sessionStart) {
        setStartDateTime(panelReviewSession.sessionStart);
        setReviewDateTimeObject(ElevateDateTime.fromUTC(panelReviewSession.sessionStart));
      }
    }
  }, [panelReviewSession]);

  const savePanelReviewSession = async () => {
    try {
      const input = {
        candidateAlias,
        workdocsLink,
        chimeLink,
        sessionStart: startDateTime,
        areaLeadAlias: skipReviewer,
        documentReviewSession: completedDocumentReview,
      };

      let result: Nullable<PanelReviewSessionResource>;
      if (panelReviewSession?.id) {
        if (
          panelReviewSession.candidateAlias !== input.candidateAlias ||
          panelReviewSession.workdocsLink !== input.workdocsLink ||
          panelReviewSession.chimeLink !== input.chimeLink ||
          panelReviewSession.sessionStart !== input.sessionStart
        ) {
          result = await panelReviewActions.update({
            ...input,
          });
        } else {
          result = panelReviewSession;
        }
      } else {
        result = await panelReviewActions.create({
          ...input,
          sessionState: PanelReviewState.SCHEDULED,
          ownerAlias: user ?? '',
        });
      }

      if (result?.id) {
        const reviewSessionId = result.id;
        const originalReviewerAliases = originalReviewers.map((item) => item.alias);
        const reviewersAliasesToAdd = reviewers.filter((reviewer) => !originalReviewerAliases.includes(reviewer));
        const reviewerAliasesToRemove = originalReviewerAliases.filter(
          (originalReviewerAlias) => !reviewers.includes(originalReviewerAlias)
        );

        const reviewerMappingIdsToRemove = reviewerAliasesToRemove.map((alias) => {
          const reviewerRecord = result.panelReviewers.find((item) => item.alias === alias);
          return reviewerRecord?.id;
        });

        if (reviewerMappingIdsToRemove.length) {
          await Promise.all(
            reviewerMappingIdsToRemove.map(async (reviewerMappingId) =>
              detachReviewer({
                id: reviewerMappingId!,
              })
            )
          );
        }

        if (reviewersAliasesToAdd.length) {
          await Promise.all(
            reviewersAliasesToAdd.map(async (reviewer) =>
              attachReviewerByAlias({
                panelReviewRecordID: reviewSessionId,
                alias: reviewer,
              })
            )
          );
        }
      }
      setId(result?.id);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSave = () => {
    if (!isParamsValid()) {
      return;
    }
    console.log(`Submitting: ${startDateTime}`);
    setIsSubmitting(true);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    savePanelReviewSession().then(() => goToPage(PanelReviewSessionsPage));
  };

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="l">
          <Button onClick={() => onSave()} variant="primary" loading={isSubmitting}>
            {getButtonText(isSubmitting, panelReviewSessionId)}
          </Button>
          <Button onClick={() => navigate(-1)} variant="link">
            Cancel
          </Button>
        </SpaceBetween>
      }
    >
      {isPanelReviewSessionLoading ? (
        <PromoSpinner variant="inline" />
      ) : (
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Candidate*" errorText={candidateValidationText || ''}>
            <Select
              placeholder="Select Candidate"
              onChange={({ detail }) => {
                if (candidateValidationText) {
                  setCandidateValidationText('');
                }
                setCandidateAlias(detail.selectedOption.value!);
                setCompletedDocumentReview('');
                const completedDocumentReviews = listDocumentReviewSessionsByCandidateAlias({
                  candidateAlias: detail.selectedOption.value!,
                  state: [DocumentReviewSessionState.COMPLETE],
                });

                void completedDocumentReviews.then((sessions) => {
                  if (sessions) {
                    setDocumentReviewOptions(
                      sessions.map((session) => ({
                        label: `${session.id} - ${session.sessionStart}`,
                        value: session.id,
                      }))
                    );

                    setDocumentReviewOptionsEnabled(sessions.length > 0);
                  }
                });
              }}
              options={candidateOptions}
              selectedOption={{ label: candidateAlias }}
              loadingText="Loading candidates"
            />
          </FormField>
          <FormField label="Skip reviewer*" errorText={skipReviewerValidationText || ''}>
            <Select
              placeholder="Select Skip Reviewer"
              onChange={({ detail }) => {
                if (skipReviewerValidationText) {
                  setSkipReviewerValidationText('');
                }
                setSkipReviewer(detail.selectedOption.label!);
              }}
              options={skipReviewerOptions}
              selectedOption={{ label: skipReviewer }}
              loadingText="Loading skip reviewers"
            />
          </FormField>
          <FormField label="Completed document review*" errorText={documentReviewValidationText || ''}>
            <Select
              placeholder="Select Completed Document Review"
              onChange={({ detail }) => {
                if (documentReviewValidationText) {
                  setDocumentReviewValidationText('');
                }
                setCompletedDocumentReview(detail.selectedOption.value!);
              }}
              options={documentReviewOptions}
              selectedOption={{ label: completedDocumentReview }}
              loadingText="Loading completed document reviews"
              disabled={!documentReviewOptionsEnabled}
            />
          </FormField>
          <FormField
            label="Calibrated Panel Reviewers*"
            description="Select 1-4 reviewers"
            errorText={reviewerValidationText}
          >
            <Multiselect
              placeholder="Select Reviewers"
              selectedOptions={reviewers.map((reviewer) => {
                return {
                  label: reviewer,
                  value: reviewer,
                };
              })}
              onChange={({ detail }) => {
                if (reviewerValidationText) {
                  setReviewerValidationText('');
                }
                setReviewers(detail.selectedOptions.map((reviewer) => reviewer.value!));
              }}
              options={reviewerOptions}
            />
          </FormField>
          <FormField label="Workdocs link to doc artifact*" errorText={wdLinkValidationText || ''}>
            <Input
              ariaRequired
              inputMode="text"
              placeholder="Ex: https://amazon.awsapps.com/workdocs-preview/index.html#/panel/1234"
              value={workdocsLink}
              onBlur={() => setWDLinkValidationText(checkWorkdocsLink?.(workdocsLink))}
              onChange={({ detail }) => {
                if (wdLinkValidationText) {
                  setWDLinkValidationText('');
                }
                setWorkdocsLink(detail.value.trim());
              }}
            />
          </FormField>
          <FormField label="Chime link*" errorText={chimeLinkValidationText || ''}>
            <Input
              ariaRequired
              inputMode="text"
              placeholder="Input Chime link (optional)"
              value={chimeLink}
              onBlur={() => setChimeLinkValidationText(checkChimeLink?.(chimeLink))}
              onChange={({ detail }) => {
                if (chimeLinkValidationText) {
                  setChimeLinkValidationText('');
                }
                setChimeLink(detail.value.trim());
              }}
            />
          </FormField>
          <FormField label="Due date*" errorText={dateTimeValidationText || ''}>
            <SpaceBetween direction="horizontal" size="xs">
              <SelectDateTime
                date={reviewDateTimeObject?.dateUTC}
                startTime={reviewDateTimeObject?.timeUTC}
                onChange={(value) => {
                  setReviewDateTimeObject(value);
                  setStartDateTime(getISO8601WithTimezoneOffset(value));
                  if (dateTimeValidationText) {
                    setDateTimeValidationText('');
                  }
                }}
              />
            </SpaceBetween>
          </FormField>
        </SpaceBetween>
      )}
    </Form>
  );
};

export default PanelReviewSessionCreateEditForm;
