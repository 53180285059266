// eslint-disable-next-line import/no-extraneous-dependencies
import Fuse, { Expression, FuseSearchOptions, IFuseOptions } from 'fuse.js';
import { useCallback, useMemo, useState } from 'react';

export default function useFuse<T>(items: T[], options?: IFuseOptions<T>) {
  const fuse = useMemo(() => new Fuse(items, options), [items, options]);
  const [filteredItems, setFilteredItems] = useState(items);
  const search = useCallback(
    (pattern: string | Expression, options?: FuseSearchOptions) => {
      if (!pattern) {
        setFilteredItems(items);
        return;
      }
      const searchedItems = fuse.search(pattern, options).map((i) => i.item);
      setFilteredItems(searchedItems);
    },
    [fuse, items]
  );

  return {
    filteredItems,
    search,
  };
}
