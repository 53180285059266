import { useState, ForwardedRef, forwardRef, memo } from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { useTableContext } from '@/contexts/CrossTableContext';
import PromoSpinner from '../common/PromoSpinner';
import { usePanelReviewSession, usePanelReviewSessionActions } from '@/api/panel-review';

interface CancelPanelReviewSessionModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  contextKey: string;
}

const CancelPanelReviewSessionComponent = (
  { contextKey, isVisible, onSetVisibility }: CancelPanelReviewSessionModalParams,
  ref: ForwardedRef<HTMLElement>
) => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { selectedId } = useTableContext(contextKey ?? '');
  const [isCancelling, setIsCancelling] = useState(false);
  const { panelReviewSession, isPanelReviewSessionLoading } = usePanelReviewSession(selectedId);
  const { reviewSessionActions, isMutating } = usePanelReviewSessionActions();

  function resetModal() {
    onSetVisibility(false);
  }

  const deletePanelReviewSession = async () => {
    if (!panelReviewSession) return;
    try {
      await reviewSessionActions.delete(panelReviewSession.id);
    } finally {
      setIsCancelling(false);
      resetModal();
    }
  };

  const onCancel = () => {
    setIsCancelling(true);
    deletePanelReviewSession();
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModal()}
      closeAriaLabel="Close dialog"
      visible={isVisible}
      size="medium"
      header="Cancel review session"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isCancelling || isMutating} onClick={() => resetModal()}>
              Back
            </Button>
            <Button
              variant="primary"
              loading={isCancelling}
              disabled={isCancelling || isMutating}
              onClick={() => onCancel()}
            >
              {isCancelling ? 'Cancelling...' : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {isPanelReviewSessionLoading ? (
        <PromoSpinner variant="inline" size="big" />
      ) : (
        <SpaceBetween direction="vertical" size="m">
          <Box variant="span">
            Cancel review session{` `}
            <Box variant="span" fontWeight="bold">
              {panelReviewSession?.id}
            </Box>
            {` `}
            permanently? This action cannot be undone.
          </Box>
          <Alert statusIconAriaLabel="Info">
            Proceeding with this action will cancel the selected panel review session.{' '}
          </Alert>
        </SpaceBetween>
      )}
    </Modal>
  );
};

const CancelPanelReviewSessionModal = memo(forwardRef(CancelPanelReviewSessionComponent));

export default CancelPanelReviewSessionModal;
