import { PanelReviewState } from '@/api/API';

export enum Vote {
  Yes = 'Yes',
  No = 'No',
}

export interface UserVote {
  inclined: Vote | null;
}

export interface Candidate {
  name: string;
  role: string;
  level: string;
  tenure: string;
  currentManager: string;
}

export interface Reviewer {
  id: string;
  label: string;
  inclinedVote: string | null;
}

export interface SessionData {
  id: string;
  state: PanelReviewState;
  candidate: Candidate;
  reviewers: Reviewer[];
  workdocsLink: string;
  chimeLink?: string;
  date: string;
  time: string;
  timezone: { label: string; value: string };
  creator: { id: string; label: string };
}

export const MIN_REVIEWERS_VOTES = 1;

export const REVIEW_SESSION_REFRESH_SECONDS = 5;
