import { MAX_REVIEWERS_PER_PANEL_REVIEW, MIN_REVIEWERS_PER_PANEL_REVIEW } from '@/common/constants';

export function checkCandidate(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'You must select a candidate';
  }
  return '';
}

export function checkSkipReviewer(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'You must select a skip reviewer';
  }
  return '';
}

export function checkDocumentReview(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'You must select a completed document review';
  }
  return '';
}

export function checkPanelReviewers(
  value: string[],
  requestorAlias: string | undefined,
  candidateAlias: string | undefined
): string {
  if (!value || !value.length || value.length < MIN_REVIEWERS_PER_PANEL_REVIEW) {
    return `At least ${MIN_REVIEWERS_PER_PANEL_REVIEW} reviewers must be selected.`;
  }
  if (requestorAlias && value.includes(requestorAlias)) {
    return 'Review session owner cannot be reviewer.';
  }
  if (candidateAlias && value.includes(candidateAlias)) {
    return 'Candidate cannot be reviewer.';
  }
  if (value.length > MAX_REVIEWERS_PER_PANEL_REVIEW) {
    return `Max of ${MAX_REVIEWERS_PER_PANEL_REVIEW} reviewers per session.`;
  }
  return '';
}

export function checkWorkdocsLink(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'Workdocs link cannot be empty';
  }

  return '';
}

export function checkChimeLink(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'Chime link cannot be empty';
  }

  return '';
}

export function checkDateTime(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'Date cannot be empty';
  }

  const date = new Date(value);
  if (Number.isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  const dateCompare = new Date();
  if (date < dateCompare) {
    return 'Date must be in the future';
  }

  dateCompare.setMonth(dateCompare.getMonth() + 6);
  if (date > dateCompare) {
    return 'Date cannot be more than 6 months in the future';
  }

  return '';
}
