import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { capitalize } from 'lodash';
import { DEFAULT_FEEDBACKS_PER_PAGE, RESOURCES } from '@/common/constants';
import {
  getPageSizes,
  getFilteredColumns,
  getTableDefinition,
  getPreferences,
} from '@/components/common/table/table-config-helper';
import {
  ColumnDefinition,
  PropertyDefinition,
  PromoTableDefinition,
  PromoTableConfig,
  PanelReviewSessionItem,
} from '@/models';
import { getFormattedDate } from '@/common/utils';
import { DatePropertyFilter, StringPropertyFilter } from '@/components/common/table/table-filters';
import { RoutableLink } from '../common/links';

/** Identifier for this table. Also used as the key for tracking context. */
export const TABLE_KEY = 'Panel reviews';

const StringKeys: (keyof PanelReviewSessionItem)[] = [
  'id',
  'candidateAlias',
  'ownerAlias',
  'createdAt',
  'updatedAt',
  'workdocsLink',
  'chimeLink',
  'sessionStart',
  'sessionState',
];
const DateKeys: (keyof PanelReviewSessionItem)[] = ['updatedAt', 'createdAt', 'sessionStart'];

const properties: { [Property in keyof Partial<PanelReviewSessionItem>]: PropertyDefinition } = {
  id: {
    key: 'id',
    label: 'Session Id',
  },
  candidateAlias: {
    key: 'candidateAlias',
    label: 'Candidate alias',
  },
  ownerAlias: {
    key: 'ownerAlias',
    label: 'Owner alias',
  },
  sessionStart: {
    key: 'sessionStart',
    label: 'Session start',
  },
  sessionState: {
    key: 'sessionState',
    label: 'Session state',
  },
};

const STATIC_COLUMN_DEFINITIONS: ColumnDefinition<PanelReviewSessionItem>[] = [
  {
    id: properties.id?.key as string,
    header: properties.id?.label as string,
    cell: (e) =>
      e.id ? <RoutableLink href={`${RESOURCES.PANEL_REVIEW_SESSION.hrefToReview}/${e.id}`} text={e.id} /> : '-',
    minWidth: 160,
    sortingField: properties.id?.key as string,
  },
  {
    id: properties.candidateAlias?.key as string,
    header: properties.candidateAlias?.label as string,
    cell: (e) => e.candidateAlias,
    minWidth: 120,
    maxWidth: 120,
    sortingField: properties.candidateAlias?.key as string,
  },
  {
    id: properties.ownerAlias?.key as string,
    header: properties.ownerAlias?.label as string,
    cell: (e) => e.ownerAlias,
    minWidth: 120,
    maxWidth: 120,
    sortingField: properties.ownerAlias?.key as string,
  },
  {
    id: properties.sessionStart?.key as string,
    header: properties.sessionStart?.label as string,
    cell: (e) => getFormattedDate(new Date(e.sessionStart)) || '-',
    sortingField: properties.sessionStart?.key as string,
  },
  {
    id: properties.sessionState?.key as string,
    header: properties.sessionState?.label as string,
    cell: (e) => capitalize(e.sessionState.replace('_', ' ')),
    minWidth: 160,
    sortingField: properties.sessionState?.key as string,
  },
];

const ColumnDefinitions = <ItemDef extends PanelReviewSessionItem>(): ColumnDefinition<ItemDef>[] => {
  return [...STATIC_COLUMN_DEFINITIONS];
};

/**
 * Definition of the keys and resource(s) used in the `FeedbackSummaryTable`.
 * See parent: {@link PromoTableDefinition}
 */
const PanelReviewSessionTableDef = getTableDefinition(TABLE_KEY, RESOURCES.PANEL_REVIEW_SESSION);

type PanelReviewSessionTableConfig = PromoTableConfig<PromoTableDefinition, PanelReviewSessionItem>;

/**
 * Functional Component to set the table configuration for all tables.
 * The {@link ViewModeDefinition viewMode} specified will define what/how things are displayed.
 * See parent: {@link PromoTableConfig}
 */
const CreateTableConfig = (): PanelReviewSessionTableConfig => {
  return {
    tableDef: PanelReviewSessionTableDef,
    allColumns: ColumnDefinitions(),
    pageSizes: getPageSizes(),
    displayableColumns: getFilteredColumns(ColumnDefinitions()),
    defaultPreferences: getPreferences(DEFAULT_FEEDBACKS_PER_PAGE, getFilteredColumns(ColumnDefinitions())),
  };
};

export function getPropertyFilters() {
  const stringFilters = StringKeys.map((key) => StringPropertyFilter(properties[key]?.key, properties[key]?.label));
  const dateFilters = DateKeys.map((key) => DatePropertyFilter(properties[key]?.key, properties[key]?.label));

  return [...stringFilters, ...dateFilters].filter((i): i is PropertyFilterProperty => i !== undefined);
}

/** Hook to fetch a new instance of the table config. */
export function useTableConfig() {
  return CreateTableConfig();
}
