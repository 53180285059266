import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import { DatePicker, Select } from '@amzn/awsui-components-react/polaris';
import {
  useCalibratedDocumentReviewerRecords,
  useDocumentReviewerActions,
  useDocumentReviewSession,
  useDocumentReviewSessionActions,
} from '@/api/document-review';
import { useAppContext } from '@/contexts';
import { CalibratedReviewerItem, DocumentReviewSessionResource, Nullable } from '@/models';
import { DocumentReviewSessionState } from '@/api/API';
import { DocumentReviewSessionsPage } from '@/common/pages';
import useNavigator from '@/common/hooks/use-navigator';
import { checkCandidate, checkDate, checkDocumentReviewers, checkWorkdocsLink } from './form-validator';
import { getButtonText } from './common-components';
import PromoSpinner from '../common/PromoSpinner';
import { useDirectReports } from '@/api/amzn-people';

interface FormParams {
  documentReviewSessionId?: string;
}

const DocumentReviewSessionCreateEditForm = ({ documentReviewSessionId }: FormParams): JSX.Element => {
  const { currentUser, spoofUser } = useAppContext();
  const user = spoofUser?.alias || currentUser?.alias;
  const [id, setId] = useState(documentReviewSessionId);
  const [candidateAlias, setCandidateAlias] = useState('');
  const { directReports } = useDirectReports(user);
  const candidateOptions = directReports.map((candidate) => ({
    label: `${candidate.name} - ${candidate.alias}`,
    value: candidate.alias,
  }));
  const [reviewers, setReviewers] = useState<string[]>([]);
  const [originalReviewers, setOriginalReviewers] = useState<CalibratedReviewerItem[]>([]);
  const [workdocsLink, setWorkdocsLink] = useState('');
  const [startDate, setStartDate] = useState('');
  const [chimeLink, setChimeLink] = useState('');
  const { documentReviewSession, isDocumentReviewSessionLoading } = useDocumentReviewSession(id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { goToPage } = useNavigator();
  const { reviewSessionActions: documentReviewActions } = useDocumentReviewSessionActions(id);
  const { attachReviewerByAlias, detachReviewer } = useDocumentReviewerActions();
  const { calibratedDocumentReviewers } = useCalibratedDocumentReviewerRecords();
  const reviewerOptions = calibratedDocumentReviewers
    .filter((reviewer) => reviewer.alias !== user && reviewer.alias !== candidateAlias)
    .map((reviewer) => ({
      label: reviewer.alias,
      value: reviewer.alias,
    }));
  const [candidateValidationText, setCandidateValidationText] = useState<string>();
  const [reviewerValidationText, setReviewerValidationText] = useState<string>();
  const [wdLinkValidationText, setWDLinkValidationText] = useState<string>();
  const [dateTimeValidationText, setDateValidationText] = useState<string>();

  const isParamsValid = () => {
    let isValid = true;

    const candidateError = checkCandidate(candidateAlias);
    const reviewersError = checkDocumentReviewers(reviewers, user, candidateAlias);
    const wdError = checkWorkdocsLink(workdocsLink);
    const dateError = checkDate(startDate);
    setCandidateValidationText(candidateError);
    setReviewerValidationText(reviewersError);
    setWDLinkValidationText(wdError);
    setDateValidationText(dateError);

    if (candidateError || reviewersError || wdError || dateError) {
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    if (documentReviewSession) {
      setCandidateAlias(documentReviewSession.candidateAlias);
      setWorkdocsLink(documentReviewSession.workdocsLink);
      setChimeLink(documentReviewSession.chimeLink ?? '');
      if (documentReviewSession?.documentReviewers.length) {
        setReviewers(documentReviewSession.documentReviewers.map((reviewer) => reviewer.alias));
        setOriginalReviewers(documentReviewSession.documentReviewers);
      }
      if (documentReviewSession.sessionStart) {
        setStartDate(documentReviewSession.sessionStart);
      }
    }
  }, [documentReviewSession]);

  const saveDocumentReviewSession = async () => {
    try {
      const input = {
        candidateAlias,
        workdocsLink,
        chimeLink,
        sessionStart: startDate,
      };

      let result: Nullable<DocumentReviewSessionResource>;
      if (documentReviewSession?.id) {
        if (
          documentReviewSession.candidateAlias !== input.candidateAlias ||
          documentReviewSession.workdocsLink !== input.workdocsLink ||
          documentReviewSession.chimeLink !== input.chimeLink ||
          documentReviewSession.sessionStart !== input.sessionStart
        ) {
          result = await documentReviewActions.update({
            ...input,
          });
        } else {
          result = documentReviewSession;
        }
      } else {
        result = await documentReviewActions.create({
          ...input,
          sessionState: DocumentReviewSessionState.SCHEDULED,
          ownerAlias: user ?? '',
        });
      }

      if (result?.id) {
        const reviewSessionId = result.id;
        const originalReviewerAliases = originalReviewers.map((item) => item.alias);
        const reviewersAliasesToAdd = reviewers.filter((reviewer) => !originalReviewerAliases.includes(reviewer));
        const reviewerAliasesToRemove = originalReviewerAliases.filter(
          (originalReviewerAlias) => !reviewers.includes(originalReviewerAlias)
        );

        const reviewerMappingIdsToRemove = reviewerAliasesToRemove.map((alias) => {
          const reviewerRecord = result.documentReviewers.find((item) => item.alias === alias);
          return reviewerRecord?.id;
        });

        if (reviewerMappingIdsToRemove.length) {
          await Promise.all(
            reviewerMappingIdsToRemove.map(async (reviewerMappingId) =>
              detachReviewer({
                id: reviewerMappingId!,
              })
            )
          );
        }

        if (reviewersAliasesToAdd.length) {
          await Promise.all(
            reviewersAliasesToAdd.map(async (reviewer) =>
              attachReviewerByAlias({
                documentReviewSessionRecordID: reviewSessionId,
                alias: reviewer,
              })
            )
          );
        }
      }
      setId(result?.id);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSave = () => {
    if (!isParamsValid()) {
      return;
    }

    setIsSubmitting(true);
    saveDocumentReviewSession().then(() => goToPage(DocumentReviewSessionsPage));
  };

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="l">
          <Button onClick={() => onSave()} variant="primary" loading={isSubmitting}>
            {getButtonText(isSubmitting, documentReviewSessionId)}
          </Button>
          <Button onClick={() => navigate(-1)} variant="link">
            Cancel
          </Button>
        </SpaceBetween>
      }
    >
      {isDocumentReviewSessionLoading ? (
        <PromoSpinner variant="inline" />
      ) : (
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Candidate*" errorText={candidateValidationText || ''}>
            <Select
              placeholder="Select Candidate"
              onChange={({ detail }) => {
                if (candidateValidationText) {
                  setCandidateValidationText('');
                }
                setCandidateAlias(detail.selectedOption.value!);
              }}
              options={candidateOptions}
              selectedOption={{ label: candidateAlias }}
              loadingText="Loading candidates"
            />
          </FormField>
          <FormField
            label="Calibrated Doc Reviewers*"
            description="Select 1-4 reviewers"
            errorText={reviewerValidationText}
          >
            <Multiselect
              placeholder="Select Reviewers"
              selectedOptions={reviewers.map((reviewer) => {
                return {
                  label: reviewer,
                  value: reviewer,
                };
              })}
              onChange={({ detail }) => {
                if (reviewerValidationText) {
                  setReviewerValidationText('');
                }
                setReviewers(detail.selectedOptions.map((reviewer) => reviewer.value!));
              }}
              options={reviewerOptions}
            />
          </FormField>
          <FormField label="Workdocs link to doc artifact" errorText={wdLinkValidationText || ''}>
            <Input
              ariaRequired
              inputMode="text"
              placeholder="Ex: https://amazon.awsapps.com/workdocs-preview/index.html#/document/1234"
              value={workdocsLink}
              onBlur={() => setWDLinkValidationText(checkWorkdocsLink?.(workdocsLink))}
              onChange={({ detail }) => {
                if (wdLinkValidationText) {
                  setWDLinkValidationText('');
                }
                setWorkdocsLink(detail.value.trim());
              }}
            />
          </FormField>
          <FormField label="Due date*" errorText={dateTimeValidationText || ''}>
            <SpaceBetween direction="horizontal" size="xs">
              <DatePicker
                placeholder="YYYY/MM/DD"
                value={startDate}
                onBlur={() => setDateValidationText(checkDate?.(startDate))}
                onChange={({ detail }) => {
                  if (dateTimeValidationText) {
                    setDateValidationText('');
                  }
                  setStartDate(detail.value.trim());
                }}
              />
            </SpaceBetween>
          </FormField>
          <FormField label="Chime link">
            <Input
              ariaRequired
              inputMode="text"
              placeholder="Input Chime link (optional)"
              value={chimeLink}
              onChange={({ detail }) => {
                setChimeLink(detail.value.trim());
              }}
            />
          </FormField>
        </SpaceBetween>
      )}
    </Form>
  );
};

export default DocumentReviewSessionCreateEditForm;
