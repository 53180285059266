// @ts-check
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import { CrossTableContextProvider } from '@/contexts/CrossTableContext';
import PanelReviewSessionsTable from '@/components/PanelReviewSessions/PanelReviewSessionsTable';
import { useAppContext } from '@/contexts';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">List of Panel Review Sessions</Header>;
};

const ListPanelReviewSessions = () => {
  const { currentUser, spoofUser } = useAppContext();
  return (
    <CrossTableContextProvider>
      <ContentLayout header={<PageHeader />}>
        <PanelReviewSessionsTable user={spoofUser ?? currentUser} />
      </ContentLayout>
    </CrossTableContextProvider>
  );
};

export default ListPanelReviewSessions;
