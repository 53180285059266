import { SpaceBetween, TextContent, Box, StatusIndicator } from '@amzn/awsui-components-react';
import { PanelReviewSessionResource } from '@/models';
import { RESOURCES } from '@/common/constants';
import { RoutableLink } from '@/components/common/links';
import { PanelReviewState } from '@/api/API';

const DocInfo: React.FC<{ panelReviewSession: PanelReviewSessionResource }> = ({ panelReviewSession }) => (
  <SpaceBetween size="m">
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session ID:
      </Box>{' '}
      <RoutableLink
        href={`${RESOURCES.PANEL_REVIEW_SESSION.hrefToReview}/${panelReviewSession.id?.toString()}`}
        text={panelReviewSession.id?.toString()}
      />
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Workdocs:
      </Box>{' '}
      <a href={panelReviewSession.workdocsLink} target="_blank" rel="noopener noreferrer">
        Link
      </a>
    </TextContent>
    {panelReviewSession.chimeLink && (
      <TextContent>
        <Box fontWeight="bold" variant="span">
          Chime Link:
        </Box>{' '}
        <a href={panelReviewSession.chimeLink} target="_blank" rel="noopener noreferrer">
          {panelReviewSession.chimeLink}
        </a>
      </TextContent>
    )}
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session Date:
      </Box>{' '}
      {panelReviewSession.sessionStart?.toString()}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session Owner:
      </Box>{' '}
      {panelReviewSession.ownerAlias?.toString()}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session State:
      </Box>{' '}
      <StatusIndicator type="pending">{panelReviewSession.sessionState}</StatusIndicator>
    </TextContent>
  </SpaceBetween>
);

export default DocInfo;
