import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import PanelReviewSessionCreateEditForm from '@/components/PanelReviewSessionCreateEditForm';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">Create panel review session</Header>;
};

const CreatePanelReviewSession = () => {
  return (
    <ContentLayout header={<PageHeader />}>
      <PanelReviewSessionCreateEditForm />
    </ContentLayout>
  );
};

export default CreatePanelReviewSession;
