import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { RESOURCES } from '@/common/constants';
import { getNameDefinition } from '@/common/labels';
import {
  PanelReviewSessionItem,
  TableHeaderParams,
  PromoTableDefinition,
  HeaderActionSetParams,
  ItemAction,
  ActionDefinition,
} from '@/models';
import { PromoButton } from '@/components/common/Buttons';
import TableHeader from '@/components/common/table/TableHeader';
import { CreatePanelReviewSessionPage, EditPanelReviewSessionPage, ReviewPanelReviewSessionPage } from '@/common/pages';
import { PanelReviewState } from '@/api/API';
import { useAppContext } from '@/contexts';

const CounterText = (items: readonly PanelReviewSessionItem[]) => {
  const totalItems = items && items.length > 0 ? items.length : 0;
  // Per AWS Style Guide always set to 0 if no items.
  if (totalItems === 0) {
    return '(0)';
  }
  return `(${totalItems})`;
};

export function getActionDefinitions(onCancel: () => void) {
  const actionDefs: ActionDefinition[] = [
    {
      action: 'cancel',
      targetType: 'function',
      actionParams: {
        doAction: onCancel,
      },
    },
    {
      action: 'edit',
      targetType: 'route',
      actionParams: { page: EditPanelReviewSessionPage },
    },
    {
      action: 'create',
      targetType: 'route',
      actionParams: { page: CreatePanelReviewSessionPage },
    },
    {
      action: 'review',
      targetType: 'route',
      actionParams: { page: ReviewPanelReviewSessionPage },
    },
  ];
  return actionDefs;
}

const Resource = RESOURCES.PANEL_REVIEW_SESSION;
const ResourceName = getNameDefinition(Resource);

/** Params - {@link HeaderActionSetParams} */
export const ActionSet = ({ selectedItem, actionDefs }: HeaderActionSetParams<PanelReviewSessionItem>) => {
  const { currentUser, spoofUser } = useAppContext();
  const user = spoofUser?.alias || currentUser?.alias;
  const actionToIsDisabled: Partial<{ [K in ItemAction]: boolean }> = {
    create: false,
    review: !selectedItem || selectedItem.sessionState === PanelReviewState.CANCELLED,
    edit: !selectedItem || selectedItem.sessionState !== PanelReviewState.SCHEDULED || user !== selectedItem.ownerAlias,
    cancel:
      !selectedItem || selectedItem.sessionState !== PanelReviewState.SCHEDULED || user !== selectedItem.ownerAlias,
  };
  const actions: ItemAction[] = ['review', 'edit', 'cancel', 'create'];
  // Action buttons have a specific order of precedence, which the caller doesn't need to know about
  // so deliberately order based on the setup specific to this action set.
  const orderedActionDefs: ActionDefinition[] = [];
  actions.forEach((action) => {
    let actionDef = actionDefs.find((def) => def.action === action);
    if (actionDef) {
      if (actionDef.action === 'edit' || actionDef.action === 'review') {
        actionDef = {
          ...actionDef,
          actionParams: {
            ...actionDef.actionParams,
            itemId: selectedItem?.id,
          },
        };
      }
      orderedActionDefs.push(actionDef);
    }
  });

  return (
    <SpaceBetween direction="horizontal" size="s">
      {orderedActionDefs.map((def) => (
        <PromoButton
          key={`reviewsession.action.${def.action}`}
          action={def.action}
          actionParams={def.actionParams}
          isDisabled={actionToIsDisabled[def.action]}
          resourceName={ResourceName}
          targetType={def.targetType}
        />
      ))}
    </SpaceBetween>
  );
};

/**
 * Table header component for a `WorkSummary` table.
 * Header text consists of the `tableKey` from {@link PromoTableDefinition}, and the total item count.
 * When all available summaries are being shown, the header will indicate the total `Approved` summaries.
 *
 * Returns a {@link https://refresh.polaris.a2z.com/components/header/?tabId=playground Polaris Header}
 */
const PanelReviewSessionHeader = ({
  items,
  ...params
}: TableHeaderParams<PromoTableDefinition, PanelReviewSessionItem>): React.ReactElement => {
  return <TableHeader {...params} counter={CounterText(items || [])} actionSet={ActionSet} />;
};

export const getHeaderComponent = () => PanelReviewSessionHeader;
