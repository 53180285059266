import React, { useState, useEffect } from 'react';
import { SpaceBetween, Cards } from '@amzn/awsui-components-react/polaris';
import CandidateInfo from './components/CandidateInfo';
import WarningModal from './components/WarningModal';
import { usePapiProfile } from '@/api/amzn-people';
import { Nullable, PanelReviewSessionResource, PanelReviewSessionVoteItem } from '@/models';
import { PanelReviewSessionVote, PanelReviewState } from '@/api/API';
import { useAppContext } from '@/contexts';
import { usePanelReviewSesionVoteRecords, usePanelReviewSessionActions } from '@/api/panel-review';
import { REVIEW_SESSION_REFRESH_SECONDS, UserVote, Vote } from './config';
import { MIN_REVIEWERS_PER_PANEL_REVIEW } from '@/common/constants';
import { PanelReviewSessionsPage } from '@/common/pages';
import useNavigator from '@/common/hooks/use-navigator';
import SessionState from './components/SessionState';
import VotingSection from './components/VotingSection';
import SessionInfo from './components/SessionInfo';

const ViewPanelReviewSession = ({ panelReviewSession }) => {
  const [localReviewSessionData, setLocalReviewSessionData] = useState<PanelReviewSessionResource>(panelReviewSession);
  const [initialSessionState, setInitialSessionState] = useState<PanelReviewState>(localReviewSessionData.sessionState);
  const [userVote, setUserVote] = useState({ inclined: null });
  const [inclinedVote, setInclinedVote] = useState<PanelReviewSessionVote>();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [overrideReason, setOverrideReason] = useState('');
  const [overrideReasonValidationText, setOverrideReasonValidationText] = useState<string>('');
  const [reviewSessionState, setReviewSessionState] = useState(localReviewSessionData.sessionState);
  const { reviewSessionActions } = usePanelReviewSessionActions(localReviewSessionData.id);
  const { listVotesBySessionId, onCreate, onUpdate } = usePanelReviewSesionVoteRecords();
  const [sessionVotes, setSessionVotes] = useState<Map<PanelReviewState, Map<string, PanelReviewSessionVoteItem>>>(
    new Map()
  );
  const { user: candidateProfile } = usePapiProfile(localReviewSessionData.candidateAlias);
  const { currentUser, spoofUser } = useAppContext();
  const { goToPage } = useNavigator();
  const user = spoofUser?.alias || currentUser?.alias;

  const isOwner = user === localReviewSessionData.ownerAlias;

  const handleSetVotes = (results: Nullable<Array<PanelReviewSessionVoteItem>>) => {
    const sessionVotesMap = new Map<PanelReviewState, Map<string, PanelReviewSessionVoteItem>>();
    if (results) {
      results.forEach((vote) => {
        if (!sessionVotesMap.has(vote.voteStage)) {
          sessionVotesMap.set(vote.voteStage, new Map());
        }
        sessionVotesMap.get(vote.voteStage)?.set(vote.alias, vote);
      });
    }
    setSessionVotes(sessionVotesMap);
  };

  useEffect(() => {
    void listVotesBySessionId({
      panelReview: localReviewSessionData.id,
      alias: user,
      state: localReviewSessionData.sessionState,
    }).then((results) => {
      handleSetVotes(results);
    });

    const pollInterval = setInterval(() => {
      void listVotesBySessionId({
        panelReview: localReviewSessionData.id,
        alias: user,
        state: localReviewSessionData.sessionState,
      }).then((results) => {
        handleSetVotes(results);
      });
    }, REVIEW_SESSION_REFRESH_SECONDS * 10000);

    return () => clearInterval(pollInterval);
  }, [listVotesBySessionId, localReviewSessionData, user]);

  const handleSaveStateChange = async (newState) => {
    if (localReviewSessionData.sessionState !== newState) {
      let runUpdate = true;
      // if (newState === PanelReviewState.COMPLETE) {
      //   let yesYesCount = 0;
      //   sessionVotes.forEach((vote) => {
      //     if (vote.panelReady && vote.panelReady === 'YES' && vote.candidateReady && vote.candidateReady === 'YES') {
      //       yesYesCount += 1;
      //     }
      //   });
      //   if (yesYesCount === 0) {
      //     runUpdate = false;
      //     setShowWarningModal(true);
      //     return;
      //   }
      // }

      if (runUpdate) {
        const updateResult = await reviewSessionActions.update({
          sessionState: newState,
          overrideReason,
        });
        if (updateResult && updateResult.sessionState === newState) {
          if (newState === PanelReviewState.COMPLETE) goToPage(PanelReviewSessionsPage);
          setReviewSessionState(updateResult.sessionState);
          setInitialSessionState(updateResult.sessionState);
          setLocalReviewSessionData(updateResult);
        }
      }
    }
  };

  const handleModalOverride = async () => {
    if (overrideReason) {
      setShowWarningModal(false);
      const updateResult = await reviewSessionActions.update({
        sessionState: PanelReviewState.COMPLETE,
        overrideReason,
      });
      if (updateResult && updateResult.sessionState === PanelReviewState.COMPLETE) {
        setReviewSessionState(updateResult.sessionState);
        setInitialSessionState(updateResult.sessionState);
        setLocalReviewSessionData(updateResult);
        goToPage(PanelReviewSessionsPage);
      }
    }
  };

  const handleModalDismiss = () => {
    setShowWarningModal(false);
    setReviewSessionState(localReviewSessionData.sessionState);
  };

  const handleStateChange = (newState) => {
    setReviewSessionState(newState);
  };

  const handleVoteRefresh = () => {
    void listVotesBySessionId({
      panelReview: localReviewSessionData.id,
      alias: user,
      state: localReviewSessionData.sessionState,
    }).then((results) => {
      handleSetVotes(results);
    });
  };

  const handleVote = (voteType, value) => {
    if (value === 'Yes') {
      setInclinedVote(PanelReviewSessionVote.YES);
    } else if (value === 'No') {
      setInclinedVote(PanelReviewSessionVote.NO);
    }
    setUserVote((prevVote) => ({ ...prevVote, [voteType]: value }));
  };

  const handleVoteSave = () => {
    console.log('Entered handleVoteSave');
    if (inclinedVote) {
      console.log('Saving vote: ', inclinedVote);
      if (
        sessionVotes.has(reviewSessionState) &&
        sessionVotes.get(reviewSessionState)?.has(user!) &&
        sessionVotes.get(reviewSessionState)?.get(user!)?.vote &&
        sessionVotes.get(reviewSessionState)?.get(user!)?.vote !== inclinedVote
      ) {
        console.log('Vote exists, updating for ', user);
        const voteId = sessionVotes.get(reviewSessionState)?.get(user!)?.id;
        console.log('Vote ID: ', voteId);
        void onUpdate({
          id: voteId!,
          // panelReview: localReviewSessionData.id,
          // reviewerAlias: user!,
          vote: inclinedVote,
        });
      } else if (
        !sessionVotes.has(reviewSessionState) ||
        (sessionVotes.has(reviewSessionState) && !sessionVotes.get(reviewSessionState)?.has(user!)) ||
        (sessionVotes.has(reviewSessionState) &&
          sessionVotes.get(reviewSessionState)?.has(user!) &&
          !sessionVotes.get(reviewSessionState)?.get(user!)?.vote) &&
          localReviewSessionData.panelReviewers.map((reviewer) => {
            return reviewer.alias === user;
          })
      ) {
        console.log('Vote does NOT exist, creating for ', user);
        void onCreate({
          panelReview: localReviewSessionData.id,
          reviewerAlias: user!,
          vote: inclinedVote,
          voteStage: reviewSessionState,
        });
      }
    }
  };

  const items = [
    {
      header: 'Candidate Information',
      content: <CandidateInfo candidateProfile={candidateProfile} />,
    },
    {
      header: 'Session Information',
      content: <SessionInfo panelReviewSession={localReviewSessionData} />,
    },
    {
      header: 'Session State',
      content: (
        <SessionState
          isOwner={isOwner}
          initialSessionState={initialSessionState}
          sessionState={reviewSessionState}
          onStateChange={handleStateChange}
          onSaveStateChange={handleSaveStateChange}
        />
      ),
    },
    {
      header: isOwner ? 'Reviewer Votes' : 'Session Voting',
      content: (
        <VotingSection
          isOwner={isOwner}
          sessionData={localReviewSessionData}
          sessionVotes={sessionVotes}
          userVote={userVote}
          onVote={handleVote}
          onSave={handleVoteSave}
          onRefresh={handleVoteRefresh}
        />
      ),
    },
  ];

  return (
    <>
      <WarningModal
        visible={showWarningModal}
        minReviewers={MIN_REVIEWERS_PER_PANEL_REVIEW}
        overrideReason={overrideReason}
        setOverrideReason={setOverrideReason}
        overrideReasonValidationText={overrideReasonValidationText}
        setOverrideReasonValidationText={setOverrideReasonValidationText}
        onDismiss={handleModalDismiss}
        onProceed={handleModalOverride}
      />
      <SpaceBetween direction="vertical" size="l">
        <Cards
          items={items}
          cardDefinition={{
            header: (item) => item.header,
            sections: [
              {
                id: 'content',
                content: (item) => item.content,
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        />
      </SpaceBetween>
    </>
  );
};

export default ViewPanelReviewSession;
