import { useMemo } from 'react';

import useFuse from '@/common/hooks/useFuse';
import type { OptionalString } from '@/common/models';
import type { TimezoneOption } from '@/common/timezones';
import ElevateSelect from '@/components/common/ElevateSelect';
import useTimezones from '@/common/hooks/ use-timezones';

type ITimezoneSelect = {
  isLoading?: boolean;
  isDisabled?: boolean;
  zoneId?: OptionalString;
  referenceDate?: string;
  onChange: (v: string) => void;
};

const TimezoneSelect = ({
  isDisabled,
  isLoading,
  zoneId,
  onChange,
  referenceDate,
}: ITimezoneSelect): React.ReactElement => {
  const { timezones, systemZoneId } = useTimezones(referenceDate);

  // these 2 are expensive to calculate, so only do as needed.
  const timezoneSelectOptions = useMemo(() => timezones.map((tz, iex) => ({ ...tz, value: `${iex}` })), [timezones]);
  const fuse = useFuse(timezoneSelectOptions, {
    keys: [
      { name: 'label', weight: 1 },
      { name: 'labelTag', weight: 1 },
      { name: 'filteringTags', weight: 0.5 },
      { name: 'id', weight: 0.5 },
    ],
  });
  const timezoneId = useMemo(() => (zoneId ?? systemZoneId).toLowerCase(), [zoneId, systemZoneId]);

  return (
    <ElevateSelect
      ariaRequired
      disabled={isDisabled}
      empty="No timezones found"
      expandToViewport
      filteringAriaLabel="Filter timezones"
      filteringType="manual"
      loadingText={!timezoneSelectOptions || isLoading ? 'Loading timezones...' : ''}
      noMatch="No timezones match the filter"
      onChange={(e) => onChange((e.detail.selectedOption as TimezoneOption).id)}
      onLoadItems={({ detail }) => fuse.search(detail.filteringText)}
      options={fuse.filteredItems}
      placeholder="Choose a timezone"
      selectedOption={timezoneSelectOptions.find((timezone) => timezone.filteringTags?.includes(timezoneId)) ?? null}
      statusType={!timezoneSelectOptions || isLoading ? 'loading' : 'finished'}
    />
  );
};

export default TimezoneSelect;
