/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo } from 'react';
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  FindUserQuery,
  FindUserQueryVariables,
  GetDirectReportsQuery,
  GetDirectReportsQueryVariables,
  GetDirectReportsExternalQuery,
  GetDirectReportsExternalQueryVariables,
  DirectReport as DirectReportModel,
  UserProfile as UserProfileModel,
  getPeopleProfileQuery,
  getPeopleProfileQueryVariables,
} from '@/api/API';
import { getDateFromAPIValueUTC } from '@/common/utils';
import {
  getDirectReports as getDirectReportsQuery,
  getDirectReportsExternal as getDirectReportsExternalQuery,
  findUser as findUserQuery,
  getPeopleprofile,
} from '../graphql/queries';
import {
  DirectReportRecord,
  PeopleProfileRecord,
  Nullable,
  OptionalString,
  PapiProfile,
  OptionalNumber,
} from '@/models';
import { AbstractModel, getConcreteModels, useApiQuery } from '@/backend/api';
import { QueryKeys } from './queryKeys';
import { useMultiQueryResult } from './use-multi-query-result';

type Model =
  | UserProfileModel
  | NonNullable<NonNullable<NonNullable<GetDirectReportsQuery['listProfilesByManager']>>['items']>[number];

function isValidModel(model: AbstractModel<Model>): boolean {
  return !!model && !model._deleted;
}

export function useAmazonLookup(alias?: OptionalString) {
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (userAlias: OptionalString) => ({
      meta: { errorMessage: `Error during lookup of alias: ${userAlias}@` },
      queryKey: QueryKeys.amazon.alias(userAlias),
      queryFn: async (): Promise<Nullable<PeopleProfileRecord>> => {
        if (!userAlias) return undefined;
        const data = await getItem<FindUserQueryVariables, FindUserQuery>({
          query: findUserQuery,
          input: { alias: userAlias },
        });
        if (!data?.findUser) {
          return undefined;
        }
        return {
          alias: data.findUser.alias,
          email: data.findUser.email,
          firstName: data.findUser.firstName,
          lastName: data.findUser.lastName,
          jobLevel: data.findUser.jobLevel,
          jobTitle: data.findUser.hrJobTitle,
          isManager: data.findUser.isManager,
          manager: {
            alias: data.findUser.managerAlias as string,
            name: data.findUser.managerName as string,
          },
        };
      },
    }),
    [getItem]
  );

  const query = useQuery({
    ...getQueryParams(alias),
    enabled: !!alias,
  });

  const findUser = useCallback(
    (userAlias: OptionalString) => queryClient.fetchQuery({ ...getQueryParams(userAlias) }),
    [queryClient, getQueryParams]
  );

  return {
    findUser,
    user: query.data,
    isLookupRunning: !!alias && query.isPending,
  };
}

export function useAmazonUserMap(aliases: string[]) {
  const { findUser } = useAmazonLookup();

  const findUserResults = useQueries({
    queries: [...new Set([...aliases])].map((alias) => ({
      queryKey: QueryKeys.amazon.alias(alias),
      queryFn: () => findUser(alias),
    })),
  });

  const activeResults = findUserResults.filter((result) => !(result.isPending && result.fetchStatus === 'idle'));
  const users = useMultiQueryResult(activeResults.map((result) => result.data));

  const aliasUserMap = useMemo(
    () => new Map(users.filter((user): user is PeopleProfileRecord => !!user).map((user) => [user.alias, user])),
    [users]
  );

  return {
    aliasUserMap,
    isAliasUserMapLoading: activeResults.some((res) => res.isPending),
  };
}

export function useDirectReports(alias: OptionalString, useExternal = true) {
  const { getItems, getExternalItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error fetching direct reports for ${alias}@` },
    queryKey: QueryKeys.directReport.alias(alias),
    queryFn: async (): Promise<DirectReportRecord[]> => {
      if (!alias) return [];
      const data = await getItems<GetDirectReportsQueryVariables, GetDirectReportsQuery>({
        query: getDirectReportsQuery,
        input: { manager: alias },
      });
      const models = getConcreteModels(data?.listProfilesByManager?.items, isValidModel);
      return (models ?? []).map((model) => ({
        alias: model.alias,
        email: model.email,
        jobLevel: model.jobLevel,
        hireDate: getDateFromAPIValueUTC(model.hireDate),
        name: model.name ?? '',
        isManager: model.isManager,
      }));
    },
    enabled: !!alias && !useExternal,
  });

  const externalQuery = useQuery({
    meta: { errorMessage: `Error fetching direct reports for ${alias}@` },
    queryKey: QueryKeys.directReport.alias(alias),
    queryFn: async (): Promise<DirectReportRecord[]> => {
      if (!alias) return [];
      const data = await getExternalItems<GetDirectReportsExternalQueryVariables, GetDirectReportsExternalQuery>({
        query: getDirectReportsExternalQuery,
        input: { alias },
      });
      return (data?.getDirectReports?.directReports || [])
        .filter((item): item is DirectReportModel => item !== null)
        .map((model) => ({
          firstName: model.firstName ?? '',
          lastName: model.lastName ?? '',
          alias: model.alias ?? '',
          jobLevel: model.jobLevel ?? 4,
          hireDate: getDateFromAPIValueUTC(model.hireDate),
          email: model.email ?? '',
          name: `${model.firstName} ${model.lastName}`,
          isManager: model.isManager,
        }));
    },
    enabled: !!alias && useExternal,
  });

  return {
    directReports: (useExternal ? externalQuery.data : query.data) ?? [],
    isDirectReportsLoading: !!alias && ((useExternal && externalQuery.isPending) || (!useExternal && query.isLoading)),
  };
}

export function usePapiProfile(alias?: OptionalString) {
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (userAlias: OptionalString) => ({
      meta: { errorMessage: `Error during lookup of alias: ${userAlias}@` },
      queryKey: QueryKeys.amazon.alias(userAlias),
      queryFn: async (): Promise<Nullable<PapiProfile>> => {
        if (!userAlias) return undefined;
        const data = await getItem<getPeopleProfileQueryVariables, getPeopleProfileQuery>({
          query: getPeopleprofile,
          input: { alias: userAlias },
        });
        if (!data?.getPeopleprofile) {
          return undefined;
        }
        return {
          username: data.getPeopleprofile.username,
          personId: data.getPeopleprofile.personId,
          firstName: data.getPeopleprofile.firstName,
          lastName: data.getPeopleprofile.lastName,
          job: data.getPeopleprofile?.job,
          manager: data.getPeopleprofile.manager,
          lastHireDate: data.getPeopleprofile.lastHireDate,
          costCenterId: data.getPeopleprofile.costCenterId,
          businessTitle: data.getPeopleprofile.businessTitle,
          jobLevel: data.getPeopleprofile.jobLevel,
        };
      },
    }),
    [getItem]
  );

  const getProfileQueryParams = useCallback(
    (userAlias: OptionalString) => ({
      meta: { errorMessage: `Error during lookup of alias: ${userAlias}@` },
      queryKey: QueryKeys.amazon.alias(userAlias),
      queryFn: async (): Promise<Nullable<PapiProfile>> => {
        if (!userAlias) return undefined;
        const data = await getItem<getPeopleProfileQueryVariables, getPeopleProfileQuery>({
          query: getPeopleprofile,
          input: { alias: userAlias },
        });
        if (!data?.getPeopleprofile) {
          return null;
        }
        return {
          username: data.getPeopleprofile.username,
          personId: data.getPeopleprofile.personId,
          firstName: data.getPeopleprofile.firstName,
          lastName: data.getPeopleprofile.lastName,
          job: data.getPeopleprofile?.job,
          manager: data.getPeopleprofile.manager,
          lastHireDate: data.getPeopleprofile.lastHireDate,
          costCenterId: data.getPeopleprofile.costCenterId,
          businessTitle: data.getPeopleprofile.businessTitle,
          jobLevel: data.getPeopleprofile.jobLevel,
        };
      },
    }),
    [getItem]
  );

  const query = useQuery({
    ...getQueryParams(alias),
    enabled: !!alias,
  });

  const usePapiProfile = useCallback(
    (userAlias: OptionalString) => queryClient.fetchQuery({ ...getQueryParams(userAlias) }),
    [queryClient, getQueryParams]
  );

  const getPapiProfile = useCallback(
    (userAlias: OptionalString) => queryClient.fetchQuery({ ...getProfileQueryParams(userAlias) }),
    [queryClient, getProfileQueryParams]
  );

  return {
    usePapiProfile,
    getPapiProfile,
    user: query.data,
    isLookupRunning: !!alias && query.isPending,
  };
}
export function useManagementChain(alias: OptionalString, lookupLimit?: OptionalNumber) {
  const { getExternalItems } = useApiQuery();

  const externalQuery = useQuery({
    meta: { errorMessage: `Error fetching direct reports for ${alias}@` },
    queryKey: QueryKeys.managementChain.alias(alias),
    queryFn: async (): Promise<PapiProfile[]> => {
      if (!alias) return [];
      const data = await getExternalItems<getPeopleProfileQueryVariables, getPeopleProfileQuery>({
        query: getPeopleprofile,
        input: { alias },
      });

      if (!data?.getPeopleprofile) {
        return [];
      }

      let currentProfile = data.getPeopleprofile;

      if (!lookupLimit) {
        // eslint-disable-next-line no-param-reassign
        lookupLimit = 3;
      }

      let lookupCount = 0;

      const managementChain: PapiProfile[] = [];

      while (currentProfile && currentProfile.manager) {
        // eslint-disable-next-line no-await-in-loop
        const profileLookup = await getExternalItems<getPeopleProfileQueryVariables, getPeopleProfileQuery>({
          query: getPeopleprofile,
          input: { alias: currentProfile.manager },
        });

        if (!profileLookup?.getPeopleprofile) {
          break;
        }

        currentProfile = profileLookup.getPeopleprofile;

        if (currentProfile) {
          managementChain.push({
            username: currentProfile.username,
            personId: currentProfile.personId,
            firstName: currentProfile.firstName,
            lastName: currentProfile.lastName,
            job: currentProfile?.job,
            manager: currentProfile.manager,
            lastHireDate: currentProfile.lastHireDate,
            costCenterId: currentProfile.costCenterId,
            businessTitle: currentProfile.businessTitle,
            jobLevel: currentProfile.jobLevel,
          });
        }

        lookupCount += 1;
        if (lookupCount === lookupLimit) {
          break;
        }
      }

      return managementChain;
    },
    enabled: !!alias,
  });

  return {
    managementChain: externalQuery.data ?? [],
    isManagementChain: !!alias && externalQuery.isPending,
  };
}
