import DatePicker from '@amzn/awsui-components-react/polaris/date-picker';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import TimeInput from '@amzn/awsui-components-react/polaris/time-input';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';

import ElevateSpinner from './ElevateSpinner';
import TimezoneSelect from './TimezoneSelect';
import { ElevateDateTime, isValidDate } from '@/common/date-time-helpers';
import type { Nullable, OptionalString } from '@/common/models';
import useTimezones from '@/common/hooks/ use-timezones';

const EMPTY_DATE_MSG = 'Date cannot be blank';
const EMPTY_TIME_MSG = 'Start time cannot be blank';

function isDateEnabled(date: Date, timezoneId: string) {
  const today = DateTime.now().setZone(timezoneId).startOf('day');
  const requestedDate = DateTime.fromJSDate(date, { zone: timezoneId }).startOf('day');
  return requestedDate.isValid && requestedDate.toMillis() > today.toMillis();
}

type ISelectDateTime = {
  date: OptionalString;
  startTime: OptionalString;
  reqTimezoneId?: string | null;
  validateNotEmpty?: boolean;
  onChange: (v: ElevateDateTime) => void;
  onTzChange?: (zone: string) => void;
  isParentLoading?: boolean;
  errorText?: { date?: OptionalString; time?: OptionalString };
  disabled?: boolean;
};

const SelectDateTime = ({
  date,
  startTime,
  reqTimezoneId,
  onChange,
  onTzChange,
  validateNotEmpty,
  isParentLoading,
  errorText,
  disabled,
}: ISelectDateTime): React.ReactElement => {
  const [localizedDate, setLocalizedDate] = useState('');
  const [localizedTime, setLocalizedTime] = useState('');
  const [overrideTimezoneId, setOverrideTimezoneId] = useState('');
  const { systemZoneId } = useTimezones();
  const timezoneId = useMemo(
    () => overrideTimezoneId || reqTimezoneId || systemZoneId,
    [overrideTimezoneId, reqTimezoneId, systemZoneId]
  );

  const onChangeValue = useCallback(
    (value: string, key: 'date' | 'time' | 'timezone') => {
      let utcDateTime: Nullable<ElevateDateTime>;
      switch (key) {
        case 'date':
          setLocalizedDate(value);
          utcDateTime = ElevateDateTime.fromLocal({ date: value, time: localizedTime }, timezoneId);
          break;
        case 'time':
          setLocalizedTime(value);
          utcDateTime = ElevateDateTime.fromLocal({ date: localizedDate, time: value }, timezoneId);
          break;
        case 'timezone':
          setOverrideTimezoneId(value);
          if (onTzChange) onTzChange(value);
          utcDateTime = startTime
            ? ElevateDateTime.fromUTC({ date: localizedDate, time: startTime }, value)
            : ElevateDateTime.fromLocal({ date: localizedDate, time: localizedTime }, value);
          break;
        default:
      }
      if (utcDateTime?.isValid) {
        onChange(utcDateTime);
      }
    },
    [localizedDate, localizedTime, onChange, onTzChange, startTime, timezoneId]
  );

  useEffect(() => {
    // The date/time passed in would be in UTC, so normalize the values to the set timezone
    const newDateTime = ElevateDateTime.fromUTC({ date, time: startTime }, timezoneId);
    if (newDateTime.isValid) {
      const newDate = newDateTime.date ?? '';
      const newTime = newDateTime.time ?? '';
      setLocalizedDate((prevDate) => (prevDate === newDate ? prevDate : newDate));
      setLocalizedTime((prevTime) => (prevTime === newTime ? prevTime : newTime));
    }
  }, [timezoneId, date, startTime]);

  return isParentLoading ? (
    <ElevateSpinner size="big" />
  ) : (
    <Grid
      gridDefinition={[
        { colspan: { default: 12, m: 6, s: 7, xs: 8 } },
        { colspan: { default: 12, m: 6, s: 5, xs: 4 } },
      ]}
    >
      <Grid gridDefinition={[{ colspan: { default: 6, s: 4 } }, { colspan: { default: 6, s: 4 } }]}>
        <FormField
          errorText={validateNotEmpty && !localizedDate ? EMPTY_DATE_MSG : errorText?.date}
          label="Date"
          description="Specify the date when the event is to occur."
          constraintText="Use YYYY/MM/DD format."
        >
          <DatePicker
            disabled={disabled}
            granularity="day"
            isDateEnabled={(date) => isDateEnabled(date, timezoneId)}
            placeholder="YYYY/MM/DD"
            value={localizedDate}
            onChange={({ detail }) => onChangeValue(detail.value, 'date')}
            openCalendarAriaLabel={(selectedDate) =>
              `Choose date${  selectedDate ? `, selected date is ${selectedDate}` : ''}`
            }
          />
        </FormField>
        <FormField
          label="Time"
          errorText={validateNotEmpty && !localizedTime ? EMPTY_TIME_MSG : errorText?.time}
          description="Specify the time the event is to start."
          constraintText="Use 24-hour format."
        >
          <TimeInput
            disabled={disabled}
            use24Hour
            placeholder="hh:mm"
            format="hh:mm"
            value={localizedTime}
            onChange={({ detail }) => onChangeValue(detail.value, 'time')}
          />
        </FormField>
      </Grid>
      <FormField label="Timezone" description="Override the default timezone.">
        <TimezoneSelect
          isDisabled={disabled}
          zoneId={timezoneId}
          referenceDate={isValidDate(localizedDate) ? localizedDate : (date ?? undefined)}
          onChange={(id) => onChangeValue(id, 'timezone')}
        />
      </FormField>
    </Grid>
  );
};

export default SelectDateTime;
