import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import Select, { type SelectProps } from '@amzn/awsui-components-react/polaris/select';
import noop from 'lodash/noop';

type ElevateSelectChangeDetail = { selectedOption: SelectProps['selectedOption'] | null };
type IElevateSelect = Omit<SelectProps, 'onChange'> & {
  onChange: (event: { detail: ElevateSelectChangeDetail }) => void;
  showClear?: boolean;
};

const nullDetail = { selectedOption: null };

const ElevateSelect = ({ showClear, ...props }: IElevateSelect): React.ReactElement => (
  <span>
    <Select {...props} />
    {showClear && (
      <Box float="right" color="text-status-info">
        <Link onFollow={() => (props.selectedOption ? props.onChange({ detail: nullDetail }) : noop())}>
          <span style={{ fontSize: '10px', fontWeight: 'bold', paddingRight: '1em' }}>Clear</span>
        </Link>
      </Box>
    )}
  </span>
);

export default ElevateSelect;
