// @ts-check
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';

import { CrossTableContextProvider } from '@/contexts/CrossTableContext';
import CalibratedPanelReviewersTable from '@/components/CalibratedPanelReviewers/CalibratedPanelReviewersTable';
import { useAppContext } from '@/contexts';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">List of Calibrated Panel Reviewers</Header>;
};

const ListCalibratedPanelReviewers = () => {
  const { currentUser, spoofUser } = useAppContext();
  return (
    <CrossTableContextProvider>
      <ContentLayout header={<PageHeader />}>
        <CalibratedPanelReviewersTable user={spoofUser ?? currentUser} />
      </ContentLayout>
    </CrossTableContextProvider>
  );
};

export default ListCalibratedPanelReviewers;
