import {
  Table,
  Header,
  SpaceBetween,
  Button,
  FormField,
  Form,
  Select,
  TextContent,
} from '@amzn/awsui-components-react';

import { UserVote, Vote } from '../config';
import { CalibratedReviewerItem, PanelReviewSessionResource, PanelReviewSessionVoteItem } from '@/models';
import { PanelReviewState } from '@/api/API';

interface VotingSectionProps {
  isOwner: boolean;
  sessionData: PanelReviewSessionResource;
  sessionVotes: Map<PanelReviewState, Map<string, PanelReviewSessionVoteItem>>;
  userVote: UserVote;
  onVote: (voteType: keyof UserVote, value: string) => void;
  onSave: () => void;
  onRefresh: () => void;
}

const VotingSection: React.FC<VotingSectionProps> = ({
  isOwner,
  sessionData,
  sessionVotes,
  userVote,
  onVote,
  onSave,
  onRefresh,
}) => {
  const isVotingEnabled =
    sessionData.sessionState === PanelReviewState.INITIAL_VOTE ||
    sessionData.sessionState === PanelReviewState.FINAL_VOTE ||
    sessionData.sessionState === PanelReviewState.FINAL_OUTCOME;

  let voteStage = '';
  // eslint-disable-next-line default-case
  switch (sessionData.sessionState) {
    case PanelReviewState.INITIAL_VOTE:
      voteStage = 'initial vote';
      break;
    case PanelReviewState.FINAL_VOTE:
      voteStage = 'final vote';
      break;
    case PanelReviewState.FINAL_OUTCOME:
      voteStage = 'final outcome';
      break;
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <SpaceBetween direction="vertical" size="l">
        <Table
          items={sessionData.panelReviewers}
          header={
            <Header
              counter={`(${sessionData.panelReviewers?.length})`}
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => {
                      console.log('REFRESHING');
                      onRefresh();
                    }}
                  >
                    Refresh
                  </Button>
                </SpaceBetween>
              }
            >
              CalibratedPanelReviewerRecord
            </Header>
          }
          columnDefinitions={[
            { header: 'Reviewer', cell: (item: CalibratedReviewerItem) => item.alias },
            {
              header: 'Initial Vote',
              cell: (item: CalibratedReviewerItem) =>
                sessionVotes.get(PanelReviewState.INITIAL_VOTE)?.get(item.alias)?.vote || '-',
            },
            {
              header: 'Final Vote',
              cell: (item: CalibratedReviewerItem) =>
                sessionVotes.get(PanelReviewState.FINAL_VOTE)?.get(item.alias)?.vote || '-',
            },
            {
              header: 'Final Outcome',
              cell: (item: CalibratedReviewerItem) =>
                sessionVotes.get(PanelReviewState.FINAL_OUTCOME)?.get(item.alias)?.vote || '-',
            },
          ]}
        />
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              {!isOwner && (
                <Button
                  variant="primary"
                  onClick={() => {
                    onSave();
                  }}
                >
                  Save
                </Button>
              )}
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            {!isOwner && (
              <FormField label={`Select your ${voteStage}`}>
                <Select
                  selectedOption={userVote.inclined ? { label: userVote.inclined } : null}
                  onChange={({ detail }) => onVote('inclined', detail.selectedOption.value as Vote)}
                  options={[
                    { label: Vote.Yes, value: Vote.Yes },
                    { label: Vote.No, value: Vote.No },
                  ]}
                  disabled={!isVotingEnabled}
                  placeholder="Select your vote"
                />
              </FormField>
            )}
            {!isVotingEnabled && (
              <TextContent>
                <p>
                  Voting is only enabled when the session is in the Initial Vote, Final Vote, and Final Outcome stages.
                </p>
              </TextContent>
            )}
          </SpaceBetween>
        </Form>
      </SpaceBetween>
    </form>
  );
};

export default VotingSection;
