import { Box, SpaceBetween, Button, Select, Form, StatusIndicator } from '@amzn/awsui-components-react';
import { PanelReviewState } from '@/api/API';

interface SessionStateProps {
  isOwner: boolean;
  initialSessionState: PanelReviewState;
  sessionState: PanelReviewState;
  onStateChange: (newState: PanelReviewState) => void;
  onSaveStateChange: (newState: PanelReviewState) => void;
}

const SessionState: React.FC<SessionStateProps> = ({
  isOwner,
  initialSessionState,
  sessionState,
  onStateChange,
  onSaveStateChange,
}) => (
  <Box>
    {isOwner ? (
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                disabled={sessionState === initialSessionState}
                onClick={() => onSaveStateChange(sessionState)}
              >
                Save
              </Button>
            </SpaceBetween>
          }
        >
          <Select
            selectedOption={{ label: sessionState }}
            onChange={({ detail }) => onStateChange(detail.selectedOption.value as PanelReviewState)}
            options={[
              {
                label: PanelReviewState.SCHEDULED,
                value: PanelReviewState.SCHEDULED,
                disabled: sessionState === PanelReviewState.SCHEDULED || sessionState !== PanelReviewState.INITIAL_VOTE,
              },
              {
                label: PanelReviewState.INITIAL_VOTE,
                value: PanelReviewState.INITIAL_VOTE,
                disabled:
                  sessionState === PanelReviewState.INITIAL_VOTE ||
                  (sessionState !== PanelReviewState.SCHEDULED && sessionState !== PanelReviewState.FINAL_VOTE),
              },
              {
                label: PanelReviewState.FINAL_VOTE,
                value: PanelReviewState.FINAL_VOTE,
                disabled:
                  sessionState === PanelReviewState.FINAL_VOTE ||
                  (sessionState !== PanelReviewState.INITIAL_VOTE && sessionState !== PanelReviewState.FINAL_OUTCOME),
              },
              {
                label: PanelReviewState.FINAL_OUTCOME,
                value: PanelReviewState.FINAL_OUTCOME,
                disabled:
                  sessionState === PanelReviewState.FINAL_OUTCOME ||
                  (sessionState !== PanelReviewState.FINAL_VOTE && sessionState !== PanelReviewState.COMPLETE),
              },
              {
                label: PanelReviewState.COMPLETE,
                value: PanelReviewState.COMPLETE,
                disabled: sessionState === PanelReviewState.COMPLETE || sessionState !== PanelReviewState.FINAL_OUTCOME,
              },
            ]}
          />
        </Form>
      </form>
    ) : (
      <StatusIndicator type="info">{sessionState}</StatusIndicator>
    )}
  </Box>
);

export default SessionState;
