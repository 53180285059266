import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import { useParams } from 'react-router-dom';
import PanelReviewSessionCreateEditForm from '@/components/PanelReviewSessionCreateEditForm';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">Edit panel review session</Header>;
};

const EditPanelReviewSession = () => {
  const { panelReviewSessionId } = useParams();
  return (
    <ContentLayout header={<PageHeader />}>
      <PanelReviewSessionCreateEditForm panelReviewSessionId={panelReviewSessionId} />
    </ContentLayout>
  );
};

export default EditPanelReviewSession;
